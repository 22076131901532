/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

body{
  font-family: 'Quicksand';
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 12px !important;
  padding-left: 0 !important;;
  padding-right: 0 !important;;
}

.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #800000 !important;
  margin-right: 50px;
}

.phone-inputs .mat-mdc-form-field {
  width: 100% !important;
}

.phone-inputs .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 1) !important;
}

.phone-inputs .mat-mdc-form-field-focus-overlay {
  background-color: #FFF;
}

.phone-inputs .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #FFF;
}

.phone-inputs .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #000;
  font-weight: 600;
}


.from-friendly-name .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary{
  color: #008080;
  width: 10px;
}

.from-friendly-name .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn{
  color: #800000;
}

.callNote{
  width: calc(100% - 4px);
  border: 2px solid lightgray;
}

.callNoteTitle.mat-dialog-title {
  margin: 0 0 20px;
  display: block;
  margin-bottom: 10px;
}

.callNote .mat-form-field-wrapper{
  padding-bottom: 0px;
  height:100%;
}

.callNote .mat-form-field-infix{
  border:0;
  padding: .2em;
}

.testResult{
  position: absolute;
    left: 268px;
    top: 1px;
}

.isSuccess{
  color: #008080;
}

.isFailure{
  color: #800000;
}


.callNote .mat-form-field-underline{
  display: none;
}

.noteToolTip{
  background-color: #008080;
  color: #FFF;
  font-size: 1em;;
}

::ng-deep .mat-tooltip {
  white-space: pre-wrap;
}

tr.mat-row:hover{
  cursor: pointer;
  background-color: lightblue;
}
